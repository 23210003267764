.cardStyles{
	border:4px solid #fffff0;
	border-radius:10px;
	box-shadow: 1px 2px 1px 1px #555;
	box-sizing: border-box;
	display:flex;
	flex-direction: row;
	justify-content: flex-start;
	width:40%;
	max-height:100vh;
	margin: 2% auto;
	transition: 300ms ease-out;
}

.cardStyles:hover{
	transform: scale(0.95);
	transition: 300ms ease-in; 
}

.imgContainer{
	background: #f1f1f0;
	width: 40%;
	height:100%;
	display: flex;
	justify-content:center;
	align-items: center;
	border-radius: 10px 0 0 10px;
	border-width: 4px 0px 4px 4px;
	border-color: #271503;
	border-style: solid;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.cardImg{
	width: 100%;
	height: 100%;
	border-radius: 5px 0 0 5px;
}

.ph{
	width: 100%;
	height: 100%;
	border-radius: 5px 0 0 5px;
	background:#271503; 
}

.textStyles{
	color:#eee;
	font-size: 24px;
	letter-spacing: 0.3rem;
	font-weight: 900;
	text-align: center;
	padding-left:2%;
}

.cardContent{
	width:60%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; 
	flex: 1;
	border-radius: 0 5px 5px 0;
	background-image: url("../../Assets/Images/brown.jpg");
	padding-bottom: 1%;
}

@media screen and (max-width:700px){
	.cardStyles{
		width:80%;
		max-height: 300px;
	}
	.cardImg{
		max-height:200px;
	}
}

@media screen and (max-width:500px){
	.cardStyles{
		width:90%;
		max-height: 300px;
	}
	.textStyles{
		font-size: 18px;
		padding-left:4%;
	}
	.cardImg{
		height:80%;
	}
}

@media screen and (max-width:300px){
	.cardStyles{
		width:96%;
		max-height: 300px;
	}
	.textStyles{
		font-size: 14px;
		padding-left:4%;
	}
	.cardImg{
		height:100%;
	}
}