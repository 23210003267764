.wrapper{
	background-image: url(../../../Assets/Images/Gallery/2A5A9998.jpg);
	background-size: cover; 
	/*background-color: #f0dac4;*/
    margin-top: 0;
	padding-bottom: 5%;
	animation: transitionIn 1.5s;
}
.imgStyles{
	width: 20vw;
	height: 40vh;
	max-height: 300px;
	max-width:200px;
	margin: 2%;
	margin-top: 5%;
	border-bottom:6px solid rgba(246,168,0,0.9);
	border-radius:10px;
	box-shadow: 1px 2px 2px 1px #22222f;
}

.imgStyles:hover{
	cursor: pointer;
	transform: scale(1.1);
	transition: 100ms ease-in; 
}

.headerContainer{
	padding:2% 0;
	text-align: center;
	margin: 0 auto;
	width:75%;
	border-bottom: 4px solid #eee;
}

.header{
	color:rgba(255,255,255,0.9);
	position:relative;
	letter-spacing: 0.5rem;
	animation:scaleText 2s forwards;
	transition: ease-in-out;
	font-size: 36px;
	font-weight:bold;
}

/*keyframes*/

@keyframes scaleText{

	from{
		left:-100px;
		font-size: 10px;
	}
	
	to{
		left: 0;
	}
	
}

/*media queries*/

@media screen and (max-width:800px){
	.imgStyles{
		width:25vw;
		height:60vh;
	}
	.wrapper{
		background-image: none;
		background-color: rgb(61, 53, 53);
	}
}

@media screen and (max-width:460px){
	.imgStyles{
		max-width:300px;
		width:70vw;
		height:45vh;
	}
	
	.headerContainer{
		width:90%;
	}

	.header{
		font-size: 24px;
	}
}

@keyframes transitionIn{
    from{
        opacity: 0;
        transform: rotateX(-10deg);
    }
  
    to{
        opacity: 1;
        transform: rotateX(0);
    }
  }