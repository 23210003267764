.page-footer{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: white;
}

.ft-hr{
    background-color: chocolate;
}

.ft-heading{
    font-size: large;
}

.ft-links{
    color: chocolate;
    font-family: 'Roboto', sans-serif;
    padding-left: 2%;
}

.ft-link{
    color: white;
    font-size: large;
    font-weight: bolder;
}

.ft-link:hover{
    color:black;
    border-radius: 4px 2px 4px 2px;
    background-color: #fca83c;
    padding: 3%;
}


@media only screen and (max-width: 800px){
    .ft-heading{
        font-size: medium;
    }

    .ft-content{
        font-size: small;
    }
}
