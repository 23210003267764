.events{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-image: url("../../Assets/Images/Events/2A5A0007.jpg");
    background-size: contain;
    background-repeat: repeat;
    background-size: cover;
    animation: transitionIn 1.5s;
}
.main-video{
    background-image: url("../../Assets/Images/brown.jpg");
    background-size: cover;
    /* background-repeat: repeat; */
    width: 100%;
    /* margin:3vw; */
    height:100vh;
    color: #faf0e6;
    display: block;
}
.video-container{
        display: flex;
        flex-flow: row wrap;
        align-content: space-between;
        justify-content: space-around;
        width: 100%;

    /* margin: 20px 20px; */
}
.jumbo-video{
    width: 80%;
    height: 70%;
}
.video-name{
    animation: pop-in 1s;
    margin:20px;
    border-bottom: 2px solid #faf0e6;
    padding-bottom: 10px;
}

jumbo-video{
    margin-top: 30px;
    width: 80%;
    height: 50%;
}

.video-titles{
    font-size: 3vh;
}
.sub-videos{
    margin-right: 40px;
    margin-left: 20px;
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-around;
}


@keyframes transitionIn{
    from{
        opacity: 0;
        transform: rotateX(-10deg);
    }
  
    to{
        opacity: 1;
        transform: rotateX(0);
    }
  }
  
  @keyframes pop-in {
    0% {
      opacity: 0;
      transform: translateY(-4rem) scale(.8);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }