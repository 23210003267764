.officials{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 4%;
    margin-top: 4%;
}

.official{
    color: black;
    margin-bottom: 2%;
    background: url("../../Assets/Images/ivory.jpg") no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid white;
    border-radius: .25rem;
    animation: transitionIn 1.5s;

    width: 20rem;
}

.officials .ct-mid-card {
    max-height:14rem;
    padding-top: 10%;
}

.official:hover{
    cursor: pointer;
	transform: scale(1.05);
    transition: 200ms ease-in;
}

.contactUs{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* background-color:#f0dac4; */
    /* background-image: url(../../Assets/Images/2A5A9998.jpg); */
    background-image: url('../../Assets/Images/Contactus/2A5A0016.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.ct-hr{
    background-color: #2C2422;
}

.ct-mid{
    margin: 5%;
    font-size: x-large;
    text-align: left;
    margin: auto;
    width: 70%;
    color: #f0dac4;
    padding: 5% 12%;
    border-radius: 10px;
    margin-bottom: 2%;
}

.ct-content{
    padding: 2%;
}

.ct-inner{
    /* background-color: rgba(37, 41, 33, 0.9); */
    background-color: rgba(39, 35, 35, 0.9);
    margin: 3% 10%;
    border-radius: 48px;
    font-family: 'Roboto', sans-serif;
}

.ct-heading{
    margin: 20px;
    border-bottom: double 2px #faf0e6;
    padding: 20px;
    color: #faf0e6;
    animation: pop-in 1s;
    font-weight: 2vw;
}

.ct-mid-cards{
    display: flex;
    justify-content: space-evenly;
    padding: 5% 12%;
}

.ct-icon{
    font-size: 2rem;
}

.ct-card-title{
    padding-top: 5%;
    font-weight: bold;
}

.ct-mid-card{
    padding: 2%;
    margin: 2%;
    width: 18rem; 
    height: 16rem;
    background: url("../../Assets/Images/ivory.jpg") no-repeat;
    background-position: center center;
    background-size: cover;
    animation: transitionIn 1.5s;
}

.ct-mid-card:hover{
    cursor: pointer;
	transform: scale(1.05);
    transition: 200ms ease-in;
}



/* .ct-btn {
    background: chocolate;
    color: #fff;
    width: 5rem;
    text-align: center;
    line-height: 300%;
    font-size: .9em;
    cursor: pointer;
    border-radius: 5%;
    margin: 0.5rem 0;
    vertical-align: middle;
    text-decoration: none;
} */

/* .ct-btn {
    background: chocolate;
    color: #fff;
    width: 5em;
    text-align: center;
    line-height: 300%;
    font-size: .9em;
    cursor: pointer;
    border-radius: 5%;
    margin: 0.5em 0;
    vertical-align: middle;
    text-decoration: none;
} */

.ct-btn {
    background: chocolate;
    color: #fff;
    text-align: center;
    padding: 5% 11%;
    font-size: .9em;
    cursor: pointer;
    border-radius: 5%;
    margin: 0.5em 0;
    vertical-align: middle;
    text-decoration: none;
    border: none;
}

.ct-btn a {
    text-decoration: none;
    color: white;
}


.ct-btn:hover, .ct-btn a:hover, .ct-btn:focus {
    background: #bc5e1b;
    color: #fff;
    text-decoration: none;
    border: none;
}

@media only screen and (max-width: 1200px){
    .ct-mid-cards{
        flex-direction: column;
        
    }
    .ct-mid-card{
        margin-left: auto;
        margin-right: auto;
        width: 18rem; 
        height: 14rem;
    }
}

@media only screen and (max-width: 800px){
   .ct-mid-card{
       font-size: medium;
       margin-bottom: 15%;
   } 
   .ct-inner{
        margin: 3% 12%;
   }

    .official{
        width: 18rem;
    }

   .ct-mid-card{
        width: 18rem; 
        height: 14rem;
    }

} 

@media only screen and (max-width: 430px){
    .ct-mid-card{
        width: 12rem; 
        height: 12rem;
        font-size: smaller;
        align-items: center;
        margin-bottom: 15%;
    }

    .official{
        width: 12rem;

    }
    .ct-inner{
        margin: 60px 40px;
        /* margin-top:60px;
        margin-left:40px ;
        margin-right: 40px; */
        border-radius: 24px;
    }
    .ct-card-title{
        font-size: small;
    }

    .ct-heading{
        font-size: x-large;
    }
}