.navbar{
    /* background-image:url('../../Assets/Images/nav.jpg'); */
    overflow: auto;
    position: relative;
    top:0;
    left: 0;
    width: 100%;
    overflow: hidden;
    /* background-color: #2C2422; */
    background-color: rgb(27, 26, 26);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size:2em;
    z-index: 100;
}
.nav-link{
    color: white;
}

.nav-item{
    padding-bottom: 2px;
    border-bottom: 2px solid chocolate ;
    margin-right: 1.7vw;
    font-size: 1rem;
    font-weight: 600; 
}
.navbar-brand img{

    width: 200px;
    height:70px;
}
.nav-link:hover{
    color:black;
    border-radius: 4px 2px 4px 2px;
    background-color: #fca83c;
}
.drop-button{
    color: whitesmoke;
    width: 50px;
}
.drop-button:hover{
    background-color: none;
}
.active-link{
    color: black;
    background-color: #fca83c;
    /* border-bottom: 2px solid chocolate; */
    border-radius: 2px;
}

@media screen and (max-width:800px){
    .navbar-brand img{
        width: 120px;
        height: 50px;
    }
    .nav-item{
        font-size: 5vw;
        margin-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
}