.cardContainer{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	height:20%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	/* background-image: url(../../Assets/Images/2A5A0450.JPG); */
	background-image: url(../../Assets//Images/Gallery/2A5A9998.jpg);
	background-size: cover;
	/* background-color: #f0dac4; */
	animation: transitionIn 1.5s;
}

@media and (max-width:900px){
	.cardContainer{
		flex-direction: column;
	}
}

@keyframes transitionIn{
    from{
        opacity: 0;
        transform: rotateX(-10deg);
    }
  
    to{
        opacity: 1;
        transform: rotateX(0);
    }
  }