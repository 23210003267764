.About{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    width: 100%;
    height: 100%;
    background-image: url(../../Assets/Images/About/2A5A0431.jpg);
    background-repeat: repeat;
    background-size: contain;
    opacity: 1;
    
}

.At-inner{
    animation: transitionIn 1s;
}


@keyframes transitionIn{
    from{
        opacity: 0;
        transform: rotateX(-10deg);
    }
  
    to{
        opacity: 1;
        transform: rotateX(0);
    }
  }
  