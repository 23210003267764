.video-card-show{
    height: 400px;
    width: 400px;
    padding: 20px;
    background-image:url("../../Assets/Images/brown.jpg");
    background-size: initial;
    margin: 40px 40px;
    color: #faf0e6;
}
.video-card-disable{
    height: 400px;
    width: 400px;
    padding: 20px;
    background-image:url("../../Assets/Images/brown.jpg");
    margin: 40px 40px;
    color: #faf0e6;
    opacity: 0.5;
    pointer-events:none;
    
}
.video-titles{
    font-size: 3vh;
    border-bottom: 2px solid #faf0e6;
    padding-bottom: 10px;
}

.sub-videos{
    margin-top: 10px;
    margin-bottom: 20px;
    width: 90%;
    height: 50%;
    pointer-events: none;
}
.content-box{
    background-image: url("../../Assets//Images/ivory.jpg");
    margin: 20px 20px;
    align-items: center;
}
.video-text{
    color: #311902;
}
.video-button{
    border: none;
    font-size: 20px;
    color:#faf0e6;
    background-color: black;
    letter-spacing: 0.5em;
    transition: 0.2s ease-out;
    border:1px solid whitesmoke;
    margin-top: 15px;
}
.video-button:hover{
    color: #faf0e6;
    border:1px solid whitesmoke;
    background-color: #311902;
    transform: scale(1.05);
    transition: 0.2s ease-in;
}