.imgCard{
	position: fixed;
	z-index: 20;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 2%;
	background: #291500;
	border-radius:10px;
	margin:2% auto;
	left: 0;
	right: 0;
	top:0;
	width:50%;
	animation: panOut 300ms forwards ease-in;
}

.close{
	color:#fffff0;
	border-radius: 50%;
	cursor: pointer;
	z-index:20;
	font-size:24px;
	margin-bottom: 2%;
}

.close:hover{
	color:#ee8888;
	z-index:30;
	transform:scale(1.2);
}

.cardImg{
	border-radius: 10px;
	z-index:20;
	height:60%;
	width:50%;	
	margin-bottom:2%;
}

.cardImg:hover{
	z-index:30;
	cursor: pointer;
}

@keyframes panOut{
	0%{
		transform:scale(0.2)
	}

	50%{
		transform: scale(0.6)
	}

	100%{
		transform:scale(1);
	}
}

@media screen and (max-width:800px){
	.imgCard{
		width:60vw;
	}
	.cardImg{
		height:80%;
		width:50%;
	}
}

@media screen and (max-width:420px){
	.imgCard{
		width:85vw;
	}
	.cardImg{
		height:80%; 
		width:80%;
		max-width:300px;
		max-height:400px;
	}
}