.brand-logo{
    height: 100px;
    width: 150px;
    padding:20px;
    margin: 10px;
    padding-bottom: 20px;
    transition: 0.5s ease-out;
}

.brand-logo:hover{
    background-color: #1f1d1c;
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
}

@media screen and (max-width:800px){
    .brand-logo{
        margin:10px;
        height: 80px;
        width: 120px;
    }
    
}