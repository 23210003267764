html,body
{
    min-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px !important;
    overflow-x: hidden; 
    scroll-behavior: smooth;
}

body {
  animation: transitionIn 1s;
  margin: 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@keyframes transitionIn{
  from{
      opacity: 0;
      transform: rotateX(-10deg);
  }

  to{
      opacity: 1;
      transform: rotateX(0);
  }
}
