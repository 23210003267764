.home{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    display: flex;
    flex-direction: row;
    flex:flex-start;
    align-items: center;
    animation: transitionIn 1.5s;
}

.brown-bg{
    display: flex;
    flex-direction: column; 
    height: 100vh;
    width: 60%;
    float: left;
    /* background-image: url("../../Assets/Images/brown.jpg"); */
    background-image: url('../../Assets/Images/Home/br.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.contents{
    margin-top: 5vh;
    letter-spacing: 0.5em;
    margin-right: 5px;
    color:#f1eae2;
    float: left;
    text-align:left;
}
.wrapper{
    padding-top:15vh;
    padding-left: 6vw;
}

.about{
    color: #311902;
    font-size: 4vh;
    font-weight: 600;
    margin-top: 40px;
    animation: pop-in 1s;
}

.main-wrapper{
    padding-top: 3vh;
    text-align: center;
}
.unipel{
    padding-left:5vw;
    font-size: 7vw;
    
}
i-text{
    background-image: url("../../Assets//Images/ivory.jpg");
    color:#311902;
}

.corporation{
    padding-top: 3vh;
    font-size: 3vw;
    padding-left: 27vw;
}
span{
    background-image: url("../../Assets//Images/ivory.jpg");
    color:#311902;
}
.welcome{
    animation-delay: 1s;
    animation:pop-in 2s;
    font-size: 3vw;
    padding-left: 2vw;
    
}

.ivory-bg{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 40%;
    background-image: url("../../Assets/Images/Home/iv.jpg");
    float: right;
    background-size: cover;
    background-repeat: no-repeat;
}    
.example_e:hover {
    
    color: #faf0e6 !important;
    font-weight: 700 !important;
    letter-spacing: 2px;
    background: none;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.3s ease 0s;;
}
.example_e {
    margin: 10% auto;
    border: none;
    background: #FAF0EF;
    color:#311902 !important;
    font-weight: 100;
    padding:1vw;
    text-transform: uppercase;
    border-radius: 6px;
    border:2px solid chocolate;
    display: inline-block;
    transition: all 0.3s ease 0s;
    }
.button_cont{
    text-align: center;
    /* padding-left: 10%; */
}

.BSCI{
    background-color:gray;
    margin-bottom:7vw;
}

:root {
	--delay: 0s;
	--duration: 2s;
	--iterations: 1;
}
/* •·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•· */

.reveal-text,
.reveal-text::after {
	animation-delay:1.5s;
	animation-iteration-count: var(--iterations, 1);
	animation-duration:5s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}

.reveal-text {
	--animation-delay: var(--delay, 0);
	--animation-duration: var(--duration, 800ms);
	--animation-iterations: var(--iterations, 1);
	position: relative;
	animation-name: clip-text;
	/* color: #FFF; */
	white-space: nowrap;
	cursor: default;
	
	&::after {
		content: "";
		position: absolute;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #f2f98b;
		transform: scaleX(0);
		transform-origin: 0 50%;
		pointer-events: none;
		animation-name: text-revealer;
	}
	
}


@keyframes clip-text {
	from {
		clip-path: inset(0 100% 0 0);
	}
	to {
		clip-path: inset(0 0 0 0);
	}
}


@keyframes text-revealer {
	
	0%, 50% {
		transform-origin: 0 50%;
	}
	
	60%, 100% {
		transform-origin: 100% 50%;		
	}

	
	60% {
		transform: scaleX(1);
	}
	
	100% {
		transform: scaleX(0);
	}
}

@media screen and (max-width:800px){
    .home{
        flex-direction: column;
    }
    .brown-bg{
        width: 100%;
    }
    .ivory-bg{
        width:100%
    }
    .unipel{
        font-size: 10vw;
        padding-left:0;
    }
    .corporation{
        font-size: 8vw;
        padding-left: 0;
    }
    .example_e{
        margin-top: 100px;
        padding: 15px 15px;
    }
    .welcome{
        padding-top: 100px;
        font-size: 10vw;
    }
    .contents{
        text-align: center;
        width: 100%;
        margin-top: 10vh;
    }
    .wrapper{
        padding: 0;
    }
    /* .button_cont{
        padding: 0;
    } */
}


@keyframes transitionIn{
    from{
        opacity: 0;
        transform: rotateX(-10deg);
    }
  
    to{
        opacity: 1;
        transform: rotateX(0);
    }
  }
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@keyframes pop-in {
    0% {
      opacity: 0;
      transform: translateY(-4rem) scale(.8);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }