.btnStyles{
	margin-top: 5%;
	border:2px solid #fffff0;
	border-radius: 5px;
	padding:3% 6%;
	font-size: 1vw;
	font-weight: bold;
	outline: none;
	background: none;
	color:white;
	box-sizing: border-box;
}

button:hover{
	background: #fca83c;
	color:#111;
	outline:none;
	border:2px solid #fca83c;
}

button:focus,button:active{
	outline:none;
	border:none;
	background: none;
}

@media screen and (max-width: 800px){
	.btnStyles{
		font-size: 2vw;
	}
}

@media screen and (max-width: 560px){
	.btnStyles{
		font-size: 3vw;
	}
}