.country-name{
    color: #faf0e6;
    display:block;
    margin:auto;
    align-items: center;
    width: 80%;
    /* background-image: url(../../Assets/Images/brown.jpg); */
    /* background-color: rgb(17, 41, 35); */
    background-color: rgb(31, 30, 30,0.9);
    margin-bottom: 5%;
    border-radius: 20px;
}
.country{
    margin: 20px;
    border-bottom: double 2px #faf0e6;
    padding: 20px;
}
.main-country{
    position: relative;
    z-index:1;
}
.main-country .country-name{
    z-index: -1;
    opacity: 1;
}