
.rotating-box{
	
	margin-left: auto;
	margin-right: auto;
	/* padding-top: 5%; */
	width: 200px;
	height: 200px;
	perspective: 1200px;
}

.single-rb{
	width: 200px;
	animation: rotate 18s linear infinite;
	transform-style:preserve-3d ;
}
.single-rb img{
	border-radius: 3px;
	width: 200px;
	height: 200px;
}

.single-rb div{
	
	width: 200px;
	height: 200px;
	position: absolute;
}

.front-side{
	transform:translateZ(100px);
}
.back-side{
	transform: rotateY(180deg)
	translateZ(100px);
}
.left-side{
	transform: rotateY(-90deg)
	translateX(-100px);
	transform-origin: left;
}
.right-side{
	transform: rotateY(90deg)
	translateX(100px);
	transform-origin: right;
}
.top-side{
	transform: rotateX(-90deg)
	translateY(-100px);
	transform-origin: top;
}

.bottom-side{
	transform: rotateX(90deg)
	translateY(100px);
	transform-origin: bottom;
}
@keyframes rotate{
	0%
	{transform: rotateY(0)}
	100%
	{transform: rotateY(360deg)}

}