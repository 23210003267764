.rectangle{
    width: 70%;
    height: 70%;
    min-height: 57vh;
    border-radius: 48px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;

}

.at-container {
    display: flex;
    justify-content: space-evenly;
    margin-left: 5%;
    margin-right: 5%;
}

.text{
    margin: 10% 9%;
    text-align: left;
    color: #311902;
    font-size: large;
    font-family: 'Roboto', sans-serif;
}

.dummy{
    flex: 1;
}

.img{
    flex: 1;
    margin-top: 5%;
    margin-bottom: 5%;
    background: white;
    opacity: 0.8;
    border-radius: 48px;
    
}


#title-top{
    margin-top: 4.2em;
    padding-top: 2.7em;
    color: white;
    
}
#title{
    background-color: rgb(49,25,2); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); 
    font-weight: bold;
    border: 3px solid #f1f1f1;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 60%;
    padding: 20px 50px;
    text-align: center;
    font-size: x-large;
    text-transform: uppercase;
    border-radius: 48px;
}



@media only screen and (max-width: 900px) {
    .img{
        margin-top: 5%;
        margin-bottom: 5%;
        background: white;
        opacity: 0.8;
        border-radius: 38px;
        margin: 5%;
    }

    .at-container {
        display: inline;
    }

    .text{
        
        text-align: left;
        color: #311902;
        padding: 5%;
    }
    
    .rectangle{
        width: 80%;
    }
}

@media only screen and (max-width: 600px){
    .text{
        font-size: small;
    }
    .rectangle{
        width: 90%;
        min-height: 400px;
    }
    
    strong{
        font-size: medium;
    }

    #title{
        font-size: large;
    }
}