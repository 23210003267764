.brand{
    background-image: url(../../Assets/Images/Brands/2A5A9978.jpg);
    background-size: contain;
    /* background-color: #f0dac4; */
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    animation: transitionIn 1s;
    margin: 0;

}
.align{
    text-align: left;
    padding-top: 20px;
}
.brand-title{
    padding: 20px;
    color: whitesmoke;
    font-size: 2.5vw;
    overflow: hidden;
    text-align: center;
    background-color:rgb(31, 32, 31,0.9);
  animation: left_to_right 3s ease;
}
@keyframes left_to_right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0;
    }
  }

@keyframes transitionIn{
    from{
        opacity: 0;
        transform: rotateX(-10deg);
    }
  
    to{
        opacity: 1;
        transform: rotateX(0);
    }
  }
/* @keyframes type{
    0%{
        width:0ch;
    }
    100%{
        width:72ch;
    }
} */

@media screen and (max-width:800px){
    .brand-title{
        font-size: 5vw;
        animation:transitionIn 3s ease ;
    }
    .brand{
      background-size: cover;
    }
    
}


.loader-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
  
  .loader {
    margin: auto;
    border: 5px dotted #311902;
    border-top: 5px solid brown;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  